@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

	// framework global styles
	@include nb-theme-global();
	@include nb-auth-global();

	@include ngx-layout();
	// loading progress bar
	@include ngx-pace-theme();

	@include nb-overrides();
}

;


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

body {
	overflow: scroll !important;
}

nb-layout.with-scroll .scrollable-container {
	height: auto !important;
}
