// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes';

$pinkResto     : #F4C3CB;
$hoverColor    : #AF627D;
$greyResto     : rgb(104, 106, 108);
$warmGreyResto : rgb(219, 213, 205);

$nb-themes     : nb-register-theme((
	color-primary-100: #FEF6F4,
	color-primary-200: #FDEBE9,
	color-primary-300: #FBDEDD,
	color-primary-400: #F8D2D5,
	color-primary-500: #F4C3CB,
	color-primary-600: #D18E9F,
	color-primary-700: #AF627D,
	color-primary-800: #8D3E61,
	color-primary-900: #752550,
	color-primary-transparent-100: rgba(244, 195, 203, 0.08),
	color-primary-transparent-200: rgba(244, 195, 203, 0.16),
	color-primary-transparent-300: rgba(244, 195, 203, 0.24),
	color-primary-transparent-400: rgba(244, 195, 203, 0.32),
	color-primary-transparent-500: rgba(244, 195, 203, 0.4),
	color-primary-transparent-600: rgba(244, 195, 203, 0.48),
	color-success-100: #EBFCE0,
	color-success-200: #D3F9C3,
	color-success-300: #B1EEA1,
	color-success-400: #8FDE84,
	color-success-500: #60C95C,
	color-success-600: #43AC48,
	color-success-700: #2E903B,
	color-success-800: #1D7430,
	color-success-900: #116029,
	color-success-transparent-100: rgba(96, 201, 92, 0.08),
	color-success-transparent-200: rgba(96, 201, 92, 0.16),
	color-success-transparent-300: rgba(96, 201, 92, 0.24),
	color-success-transparent-400: rgba(96, 201, 92, 0.32),
	color-success-transparent-500: rgba(96, 201, 92, 0.4),
	color-success-transparent-600: rgba(96, 201, 92, 0.48),
	color-info-100: #CBFDFE,
	color-info-200: #98F4FE,
	color-info-300: #65E4FE,
	color-info-400: #3FD0FD,
	color-info-500: #00B0FC,
	color-info-600: #0088D8,
	color-info-700: #0066B5,
	color-info-800: #004892,
	color-info-900: #003478,
	color-info-transparent-100: rgba(0, 176, 252, 0.08),
	color-info-transparent-200: rgba(0, 176, 252, 0.16),
	color-info-transparent-300: rgba(0, 176, 252, 0.24),
	color-info-transparent-400: rgba(0, 176, 252, 0.32),
	color-info-transparent-500: rgba(0, 176, 252, 0.4),
	color-info-transparent-600: rgba(0, 176, 252, 0.48),
	color-warning-100: #FDFCD4,
	color-warning-200: #FCF9AA,
	color-warning-300: #F7F27E,
	color-warning-400: #EFE95D,
	color-warning-500: #E5DC2B,
	color-warning-600: #C4BC1F,
	color-warning-700: #A49C15,
	color-warning-800: #847D0D,
	color-warning-900: #6D6608,
	color-warning-transparent-100: rgba(229, 220, 43, 0.08),
	color-warning-transparent-200: rgba(229, 220, 43, 0.16),
	color-warning-transparent-300: rgba(229, 220, 43, 0.24),
	color-warning-transparent-400: rgba(229, 220, 43, 0.32),
	color-warning-transparent-500: rgba(229, 220, 43, 0.4),
	color-warning-transparent-600: rgba(229, 220, 43, 0.48),
	color-danger-100: #FFE9E2,
	color-danger-200: #FFCEC6,
	color-danger-300: #FFAEAA,
	color-danger-400: #FF9599,
	color-danger-500: #FF7285,
	color-danger-600: #DB5372,
	color-danger-700: #B73961,
	color-danger-800: #932452,
	color-danger-900: #7A1548,
	color-danger-transparent-100: rgba(255, 114, 133, 0.08),
	color-danger-transparent-200: rgba(255, 114, 133, 0.16),
	color-danger-transparent-300: rgba(255, 114, 133, 0.24),
	color-danger-transparent-400: rgba(255, 114, 133, 0.32),
	color-danger-transparent-500: rgba(255, 114, 133, 0.4),
	color-danger-transparent-600: rgba(255, 114, 133, 0.48),
	layout-padding-top: 2.25rem,
	menu-item-icon-margin: 0 0.5rem 0 0,
	card-height-tiny: 13.5rem,
	card-height-small: 21.1875rem,
	card-height-medium: 28.875rem,
	card-height-large: 36.5625rem,
	card-height-giant: 44.25rem,
	card-margin-bottom: 1.875rem,
	card-header-with-select-padding-top: 0.5rem,
	card-header-with-select-padding-bottom: 0.5rem,
	select-min-width: 6rem,
	slide-out-background: #F7F9FC,
	slide-out-shadow-color: 0 4px 14px 0 #8F9BB3,
	slide-out-shadow-color-rtl: 0 4px 14px 0 #8F9BB3,
), default, default);

$nb-themes     : nb-register-theme((color-primary-100: #FEF6F4,
	color-primary-200: #FDEBE9,
	color-primary-300: #FBDEDD,
	color-primary-400: #F8D2D5,
	color-primary-500: #F4C3CB,
	color-primary-600: #D18E9F,
	color-primary-700: #AF627D,
	color-primary-800: #8D3E61,
	color-primary-900: #752550,
	color-primary-transparent-100: rgba(244, 195, 203, 0.08),
	color-primary-transparent-200: rgba(244, 195, 203, 0.16),
	color-primary-transparent-300: rgba(244, 195, 203, 0.24),
	color-primary-transparent-400: rgba(244, 195, 203, 0.32),
	color-primary-transparent-500: rgba(244, 195, 203, 0.4),
	color-primary-transparent-600: rgba(244, 195, 203, 0.48),
	color-success-100: #EBFCE0,
	color-success-200: #D3F9C3,
	color-success-300: #B1EEA1,
	color-success-400: #8FDE84,
	color-success-500: #60C95C,
	color-success-600: #43AC48,
	color-success-700: #2E903B,
	color-success-800: #1D7430,
	color-success-900: #116029,
	color-success-transparent-100: rgba(96, 201, 92, 0.08),
	color-success-transparent-200: rgba(96, 201, 92, 0.16),
	color-success-transparent-300: rgba(96, 201, 92, 0.24),
	color-success-transparent-400: rgba(96, 201, 92, 0.32),
	color-success-transparent-500: rgba(96, 201, 92, 0.4),
	color-success-transparent-600: rgba(96, 201, 92, 0.48),
	color-info-100: #CBFDFE,
	color-info-200: #98F4FE,
	color-info-300: #65E4FE,
	color-info-400: #3FD0FD,
	color-info-500: #00B0FC,
	color-info-600: #0088D8,
	color-info-700: #0066B5,
	color-info-800: #004892,
	color-info-900: #003478,
	color-info-transparent-100: rgba(0, 176, 252, 0.08),
	color-info-transparent-200: rgba(0, 176, 252, 0.16),
	color-info-transparent-300: rgba(0, 176, 252, 0.24),
	color-info-transparent-400: rgba(0, 176, 252, 0.32),
	color-info-transparent-500: rgba(0, 176, 252, 0.4),
	color-info-transparent-600: rgba(0, 176, 252, 0.48),
	color-warning-100: #FDFCD4,
	color-warning-200: #FCF9AA,
	color-warning-300: #F7F27E,
	color-warning-400: #EFE95D,
	color-warning-500: #E5DC2B,
	color-warning-600: #C4BC1F,
	color-warning-700: #A49C15,
	color-warning-800: #847D0D,
	color-warning-900: #6D6608,
	color-warning-transparent-100: rgba(229, 220, 43, 0.08),
	color-warning-transparent-200: rgba(229, 220, 43, 0.16),
	color-warning-transparent-300: rgba(229, 220, 43, 0.24),
	color-warning-transparent-400: rgba(229, 220, 43, 0.32),
	color-warning-transparent-500: rgba(229, 220, 43, 0.4),
	color-warning-transparent-600: rgba(229, 220, 43, 0.48),
	color-danger-100: #FFE9E2,
	color-danger-200: #FFCEC6,
	color-danger-300: #FFAEAA,
	color-danger-400: #FF9599,
	color-danger-500: #FF7285,
	color-danger-600: #DB5372,
	color-danger-700: #B73961,
	color-danger-800: #932452,
	color-danger-900: #7A1548,
	color-danger-transparent-100: rgba(255, 114, 133, 0.08),
	color-danger-transparent-200: rgba(255, 114, 133, 0.16),
	color-danger-transparent-300: rgba(255, 114, 133, 0.24),
	color-danger-transparent-400: rgba(255, 114, 133, 0.32),
	color-danger-transparent-500: rgba(255, 114, 133, 0.4),
	color-danger-transparent-600: rgba(255, 114, 133, 0.48),
	layout-padding-top: 2.25rem,
	menu-item-icon-margin: 0 0.5rem 0 0,
	card-height-tiny: 13.5rem,
	card-height-small: 21.1875rem,
	card-height-medium: 28.875rem,
	card-height-large: 36.5625rem,
	card-height-giant: 44.25rem,
	card-margin-bottom: 1.875rem,
	card-header-with-select-padding-top: 0.5rem,
	card-header-with-select-padding-bottom: 0.5rem,
	select-min-width: 6rem,
	slide-out-background: linear-gradient(270deg, #222B45 0%, #151A30 100%),
	slide-out-shadow-color: 0 4px 14px 0 #8F9BB3,
	slide-out-shadow-color-rtl: 0 4px 14px 0 #8F9BB3,
), dark, dark);


@include nb-install-component {
	::ng-deep .table {
		color       : nb-theme(text-basic-color); /* This will change the color when you switch the theme */
		font-family : nb-theme(font-family-primary);
	}
}
